import { API } from "../../configs/constants"



export const createFetch = ({ dispatch, getState }) => next =>  async action => {

  const {
    type,
    types,
    fetchData,
  } = action;
  
  if (type!=="API") return next(action);

  const [pendingType, successType, errorType] = types;

  let {endpoint, method = 'GET', body = null, headers = {},props={}} = fetchData;
  headers['authorization'] =  `public ${getState().auth.token}`
  if (body) {
    body = JSON.stringify(body)
  }

  dispatch({ type: pendingType });
  
  try{
    const req = await  fetch(API+endpoint,{method, body, headers})

    try{
      const res = await req.json()
      dispatch({ type: successType, payload:res })
    }catch(error){
      dispatch({ type: successType, payload:props }) 
    }
    
    return Promise.resolve(getState());

  }catch(error){
    
    dispatch({type: errorType,payload: error});
    return Promise.reject(error);

  }

}