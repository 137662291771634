export const twntySignatureConfig = {
  title: "twnty signature",
  bodyInlineStyles: "",
  headStringTags: `
    <style type="text/css">
      table {border-collapse:separate;}
      a, a:link, a:visited {text-decoration: none; color: #00788a;}
      a:hover {text-decoration: underline;}
      h2,h2 a,h2 a:visited,h3,h3 a,h3 a:visited,h4,h5,h6,.t_cht {color:#000 !important;}
      .ExternalClass p, .ExternalClass span, .ExternalClass font, .ExternalClass td {line-height: 100%;}
      .ExternalClass {width: 100%;}
    </style>
  `,
};
