import copyToClipboard from "./copyToClipboard";
import ReactDOMServer from "react-dom/server";
import { toast } from "react-toastify";

export default function renderSignature({
  title,
  signature,
  headConfig,
  bodyStyles,
}) {
  var html = ReactDOMServer.renderToStaticMarkup(signature);
  var rawHtml = `
    <!doctype html>
    <html lang="en">
    <head>
      <meta charset="utf-8">
      <meta http-equiv="Content-Type" content="text/html charset=UTF-8" />
      <title>${title}</title>
      ${headConfig}
    </head>
    <body style="${bodyStyles && bodyStyles}">${html}</body>
    </html>
  `;

  copyToClipboard(rawHtml);
}
