import React from "react";

const PriwattSignature = ({ data }) => {
  const { name, position, email, phone, greeting } = data;
  return (
    <>
      <table style={{ borderCollapse: "collapse", fontSize: "12px" }}>
        <tbody style={{ verticalAlign: "top" }}>
          <tr>
            <td
              style={{
                paddingBottom: "15px",
                fontSize: "14px",
                borderBottom: "1px solid #E7E9EF",
              }}
              className="td-pdbtm-20px"
            >
              {greeting}
            </td>
          </tr>
          <tr style={{ fontWeight: "bold" }}>
            <td
              style={{
                paddingBottom: "15px",
                paddingTop: "15px",
                lineHeight: "20px",
              }}
              className="td-pdbtm-20px"
            >
              <span style={{ color: "#0C215A" }}>{name}</span>
              <span style={{ fontWeight: "normal", margin: "0 4px" }}>|</span>
              <span style={{ color: "#0C215A" }}>{position}</span>
            </td>
          </tr>
          <tr>
            <td style={{ paddingBottom: "2px" }} className="td-pdbtm-8px">
              <a
                style={{ color: "#0C215A", textDecoration: "none" }}
                href={`mailto:${email}`}
              >
                {email}
              </a>
            </td>
          </tr>
          <tr>
            <td style={{ paddingBottom: "3px" }} className="td-pdbtm-8px">
              <a
                style={{ color: "#0C215A", textDecoration: "none" }}
                href={`tel:${phone}`}
              >
                {phone}
              </a>
            </td>
          </tr>
          <tr>
            <td
              style={{ paddingBottom: "12px", color: "#0C215A" }}
              className="td-pdbtm-20px"
            >
              Pfaffendorfer Straße 26, 04105 Leipzig, Deutschland
            </td>
          </tr>
          <tr>
            <td>
              <table>
                <tbody>
                  <tr>
                    <td
                      style={{
                        paddingBottom: "12px",
                        width: "101px",
                        paddingLeft: "0",
                      }}
                      className="logo-td"
                    >
                      <a href="https://priwatt.de/" target="_blank">
                        <img
                          src="https://priwatt.de/wordpress/wp-content/uploads/2021/11/Logo.png"
                          alt="priwatt site"
                          width="101"
                          height="auto"
                          style={{ display: "block", height: "auto" }}
                        />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{ paddingBottom: "5px", verticalAlign: "bottom" }}
                      className="socials-td"
                    >
                      <table
                        style={{ width: "auto", borderCollapse: "collapse" }}
                      >
                        <tbody>
                          <tr>
                            <td
                              style={{
                                width: "23px",
                                paddingRight: "15px",
                                paddingLeft: "0",
                              }}
                              className="icon-td"
                            >
                              <a
                                href="https://www.facebook.com/priwatt.energy"
                                target="_blank"
                              >
                                <img
                                  src="https://priwatt.de/wordpress/wp-content/uploads/2021/11/facebook.png"
                                  width="23"
                                  height="auto"
                                  style={{ display: "block", height: "auto" }}
                                />
                              </a>
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                                width: "23px",
                                paddingRight: "15px",
                                paddingLeft: "0",
                              }}
                              className="icon-td"
                            >
                              <a
                                href="https://www.linkedin.com/company/priwatt"
                                target="_blank"
                              >
                                <img
                                  src="https://priwatt.de/wordpress/wp-content/uploads/2021/11/linkedin.png"
                                  width="23"
                                  height="auto"
                                  style={{ display: "block", height: "auto" }}
                                />
                              </a>
                            </td>
                            <td
                              style={{
                                textAlign: "right",
                                width: "23px",
                                paddingRight: "15px",
                                paddingLeft: "0",
                              }}
                              className="icon-td"
                            >
                              <a
                                href="https://www.instagram.com/priwatt_energy"
                                target="_blank"
                              >
                                <img
                                  src="https://priwatt.de/wordpress/wp-content/uploads/2021/11/instagram.png"
                                  width="23"
                                  height="auto"
                                  style={{ display: "block", height: "auto" }}
                                />
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{ padding: "0 0 11px 0" }}
                      className="td-pdbtm-30px"
                    >
                      <a
                        className="address-td"
                        href="https://priwatt.de/"
                        style={{
                          color: "#0C215A",
                          textDecoration: "none",
                          letterSpacing: "0.8px",
                          fontSize: "12px",
                        }}
                        target="_blank"
                      >
                        www.priwatt.de
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr style={{ borderTop: "1px solid #E7E9EF" }}>
            <td
              style={{ paddingTop: "17px", fontSize: "10px", color: "#7784A5" }}
              className="td-pdt-30px"
            >
              <span> Geschäftsführung: Lukas Hoffmeier, Kay Theuer.&nbsp;</span>
              <span style={{ display: "inline-block" }}>
                Handelsregister: Amtsgericht Leipzig HRB 37797.
              </span>
              <br />
              USt-IdNr: DE333823164.
            </td>
          </tr>
          <tr>
            <td
              style={{ paddingTop: "8px", fontSize: "10px", color: "#7784A5" }}
            >
              Bitte denke an die Umwelt, bevor du diese E-Mail ausdruckst.
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default PriwattSignature;
