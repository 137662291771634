import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authInit } from "../store/ducks/auth";
import Keycloak from "keycloak-js";
import { prodKeycloack } from "../configs/constants";
import Signature from "./components/Signature/Signature";
import PriwattSignature from "./components/PriwattSignature/PriwattSignature";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import UserData from "./components/UserData/UserData";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import renderSignature from "../utils/renderSignature";
import { priwatSignatureConfig } from "./components/PriwattSignature/signature.config";
import { twntySignatureConfig } from "./components/Signature/signature.config";
const keycloak = new Keycloak(prodKeycloack);

function App() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState({
    name: "",
    position: "",
    email: "",
    phone: "",
    localization: "de",
  });
  const [priwattData, setPriwattData] = useState({
    greeting: "Sonnige Grüße",
    name: "",
    position: "",
    email: "",
    phone: "",
    localization: "de",
  });

  let SignatureBox = <Signature data={data} loading={isLoading} />;
  const getSignature = () => {
    renderSignature({
      title: twntySignatureConfig.title,
      signature: SignatureBox,
      headConfig: twntySignatureConfig.headStringTags,
    });
    toast("Copied! Now you can use it!!!");
  };

  let PriwattSignatureBox = <PriwattSignature data={priwattData} />;
  const getPriwattSignature = () => {
    renderSignature({
      title: priwatSignatureConfig.title,
      signature: PriwattSignatureBox,
      bodyStyles: priwatSignatureConfig.bodyInlineStyles,
      headConfig: priwatSignatureConfig.headStringTags,
    });
    toast("Copied! Now you can use it!!!");
  };

  useEffect(() => {
    setLoading(true);
    keycloak.init({ onLoad: "login-required" }).success((authenticated) => {
      dispatch(authInit(keycloak));
      setLoading(false);
    });
  }, []);

  useEffect(async () => {
    if (auth.authenticated) {
      setData((prev) => ({
        ...prev,
        name: auth.tokenParsed.name,
        email: auth.tokenParsed.email,
      }));
    }
  }, [auth.authenticated]);

  if (!auth.authenticated) return false;
  return (
    <>
      <div className="app">
        <Tabs>
          <TabList>
            <Tab>Twnty</Tab>
            <Tab>Priwatt</Tab>
          </TabList>
          <TabPanel>
            <div className="main">
              <UserData
                initUserData={data}
                setData={setData}
                onSuccess={getSignature}
                disableFields={{
                  greeting: true,
                }}
              />
              {SignatureBox}
            </div>
          </TabPanel>
          <TabPanel>
            <div className="main">
              <UserData
                initUserData={priwattData}
                setData={setPriwattData}
                onSuccess={getPriwattSignature}
                disableFields={{
                  avatar: true,
                  localization: true,
                  disclaimer: true,
                }}
              />
              {PriwattSignatureBox}
            </div>
          </TabPanel>
        </Tabs>

        <ToastContainer />
      </div>
    </>
  );
}

export default App;
