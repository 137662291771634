let API = "https://signature.twntydigital.de/api/";
let URL = "https://signature.twntydigital.de/";

if (window.location.hostname === "localhost") {
  API = "http://localhost/signature.loc/public/api/";
  URL = "http://localhost/signature.loc";
}

const getUserAvatar = async (auth, stateHandler) => {
  let request = await fetch(`${API}avatars.php?id=${auth.subject}`, {
    method: "GET",
  })
    .then((res) => res.json())
    .catch((error) => console.log("Cannot get avatar.", error.message));
  let res = await request;
  if (res?.path) {
    stateHandler((prev) => ({ ...prev, avatar: URL + res.path }));
  }
};

const uploadUserAvatar = async (file, auth) => {
  let form_data = new FormData();
  form_data.append("avatar", file);
  form_data.append("id", auth.subject);
  let request = await fetch(`${API}avatars.php`, {
    method: "POST",
    body: form_data,
  })
    .then((res) => res.json())
    .catch((error) => console.log("Cannot upload avatar.", error.message));
  let res = await request;
  return res;
};

const removeAvatar = async (auth, stateHandler) => {
  let request = await fetch(`${API}avatars.php?id=${auth.subject}`, {
    method: "DELETE",
  });
  if (request.ok) stateHandler((prev) => ({ ...prev, avatar: null }));
};

export { getUserAvatar, uploadUserAvatar, removeAvatar };
