export const priwatSignatureConfig = {
  title: "Priwatt Signature",
  bodyInlineStyles:
    "font-family: Inter, Arial; font-size: 14px; color:#0C215A;",
  headStringTags: `<style>
      @media only screen and (max-width: 750px) {
        .icon-td {
          width: 20px !important;
          padding-right: 10px !important;
        }

        .socials-td {
          padding-bottom: 5px !important;
        }

        .icon-td img {
          width: 20px !important;
        }

        .logo-td {
          width: 100px !important;
          padding-bottom: 5px !important;
        }

        .logo-td img {
          width: 100px !important;
        }

        .td-pdbtm-8px {
          padding-bottom: 4px !important;
        }

        .td-pdbtm-20px {
          padding-bottom: 10px !important;
        }

        .td-pdbtm-30px {
          padding-bottom: 12px !important;
        }

        .td-pdt-30px {
          padding-top: 12px !important;
        }

        .address-td {
          letter-spacing: normal !important;
        }
      }
    </style>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap" rel="stylesheet">`,
};
