import { KEYCLOAK_AUTH_INIT } from "./actions";

const defaultState = {
  authenticated:false
}
 
const authReducer = (state = defaultState , {type,payload} )=>{
  
  switch(type){    
    case KEYCLOAK_AUTH_INIT:
      return {
        ...payload
      }
      

    default:
      return state
  }
}


export default authReducer