import { combineReducers, createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import { createFetch } from './middlewares/fetch'

import { routerReducer } from 'react-router-redux'

import { editor , auth ,keywords} from './ducks'


let reducers = combineReducers({
  router:routerReducer,
  auth
})


let middleware = [createFetch,thunk]
const store = createStore(reducers, composeWithDevTools(applyMiddleware(...middleware)))
export default store