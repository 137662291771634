import React from "react";

const styleP = {
  margin: 0,
  fontSize: 12,
};

const disclaimers = {
  empty: "",
  ua: "Це повідомлення та всі додатки до нього є конфіденційними і призначені виключно для використання отримувачем(адресатом) вказаним вище. Якщо ви не є отримувачем цього повідомлення- негайно видаліть із системи. Використання інформації що міститься у цьому повідомленні допускається лише з дотриманням вимог законодавства України про захист комерційної таємниці та укладених угод, щодо захисту комерційної таємниці.",
};
const localizations = {
  de: {
    company:
      "TWNTY Digital GmbH, Geschäftsführer Kay Theuer, USt-IdNr: DE297665760, Handelsregister: Amtsgericht Leipzig, HRB 31482",
    address: (
      <>
        TWNTY Digital GmbH, Schillerstraße 4 <br /> 04109 Leipzig /
        Deutschland
      </>
    ),
  },
  en: {
    company:
      "TWNTY Digital GmbH, Managing Director Kay Theuer, Sales tax number: DE297665760, Commercial register: Amtsgericht Leipzig, HRB 31482",
    address: (
      <>
        TWNTY Digital GmbH, Schillerstraße 4 <br /> 04109 Leipzig /
        Germany
      </>
    ),
  },
  ua: {
    company:
      "TWNTY Digital GmbH, Managing Director Kay Theuer, Sales tax number: DE297665760, Commercial register: Amtsgericht Leipzig, HRB 31482",
    address: (
      <>
        TWNTY Digital GmbH, Schillerstraße 4 <br /> 04109 Leipzig /
        Germany
      </>
    ),
  },
};

const Signature = ({ data, loading }) => {
  const { name, position, email, phone, avatar, disclaimer, localization } =
    data;
  return (
    <table
      style={{
        borderCollapse: "collapse",
        maxWidth: "550px",
        fontFamily: "Arial",
        fontSize: "12px",
        color: "#1F242D",
        margin: 0,
      }}
    >
      <tbody>
        <tr style={{ borderTop: "1px solid #1F242D" }}>
          <td style={{ paddingBottom: "14px", paddingTop: "10px" }}>
            <p style={{ fontWeight: "bold", ...styleP }}>{name}</p>
            <p style={{ ...styleP }}>{position}</p>
          </td>
          <td
            rowSpan="2"
            style={{
              textAlign: "right",
              paddingTop: "14px",
              verticalAlign: "top",
            }}
          >
            {avatar && <img width="80" height="80" src={avatar} />}
          </td>
        </tr>
        <tr>
          <td style={{ paddingBottom: "14px" }}>
            <a
              style={{ color: "#1F242D", textDecoration: "none" }}
              href={`mailto:${email}`}
            >
              {email}
            </a>
            <br />
            +49 (0)341 2217 9600 <br />
            {phone}
          </td>
        </tr>

        <tr>
          <td style={{ paddingBottom: "14px" }}>
            {localizations[localization].address}
          </td>
          <td style={{ textAlign: "right", float: "right" }}>
            <table
              style={{
                textAlign: "right",
                float: "right",
                marginLeft: "auto",
              }}
            >
              <tbody>
                <tr>
                  <td>
                    <a
                      href="https://www.linkedin.com/company/twnty/"
                      target="_blank"
                    >
                      <img
                        width="17"
                        height="17"
                        src="https://signature.twntydigital.de/icons/ln.png"
                        alt=""
                      />
                    </a>
                  </td>
                  <td>
                    <a
                      href="https://www.instagram.com/hey_twnty/"
                      target="_blank"
                    >
                      <img
                        width="17"
                        height="17"
                        src="https://signature.twntydigital.de/icons/in.png"
                        alt=""
                      />
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr style={{ borderTop: "1px solid #1F242D" }}>
          <td style={{ paddingTop: "20px", paddingBottom: "20px" }}>
            <a
              style={{
                fontWeight: "bold",
                color: "#1F242D",
                textDecoration: "none",
                position: "relative",
                bottom: "-8px",
              }}
              target="_blank"
              href="https://twntydigital.de/"
            >
              www.twntydigital.de
            </a>
          </td>
          <td style={{ textAlign: "right", verticalAlign: "bottom" }}>
            <a target="_blank" href="https://twntydigital.de/">
              <img
                width="83"
                src="https://signature.twntydigital.de/icons/logo.png"
                alt=""
              />
            </a>
          </td>
        </tr>
        <tr style={{ borderTop: "1px solid #1F242D", paddingTop: "14px" }}>
          <td
            colSpan="2"
            style={{
              color: "#8f8f92",
              fontSize: "11px",
              lineHeight: "16px",
              textAlign: "justify",
              WebkitHyphens: "auto",
              MsHyphens: "auto",
              hyphens: "auto",
            }}
          >
            <p style={{ ...styleP, marginTop: 14, fontSize: 10 }}>
              {localizations[localization].company}
            </p>

            <p style={{ ...styleP, marginTop: 14, fontSize: 10 }}>
              {disclaimers[disclaimer]}
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default Signature;
