import React from "react";
import "./style.scss";
import { useSelector } from "react-redux";
import * as UserService from "../../../services/user.service";

const UserData = ({ initUserData, setData, onSuccess, disableFields }) => {
  const auth = useSelector((state) => state.auth);

  const onChangeHandler = (e) => {
    if (e.target.name === "avatar") return false;
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  React.useEffect(async () => {
    await UserService.getUserAvatar(auth, setData);
    return () => {
      UserService.removeAvatar(auth, setData);
    };
  }, [initUserData.avatar]);

  const onSubmitHandler = (e) => {
    e.preventDefault();
    onSuccess();
  };

  const fileHandler = async (e) => {
    let file = e.target.files[0];
    if (file) {
      let res = await UserService.uploadUserAvatar(file, auth);
      setData((prev) => ({
        ...prev,
        avatar: URL + res?.path + `?v=${Date.now()}`,
      }));
    }
  };

  const clearAvatar = (e) => {
    e.preventDefault();
    UserService.removeAvatar(auth, setData);
  };

  return (
    <div className="data-box">
      <h2 className="title">Your info:</h2>
      <form
        onChange={onChangeHandler}
        onSubmit={onSubmitHandler}
        className="form"
      >
        {!disableFields?.greeting && (
          <input
            type="text"
            name="greeting"
            className="input"
            placeholder="Greeting"
            defaultValue={initUserData.greeting}
            required
          />
        )}
        <input
          type="text"
          defaultValue={initUserData.name}
          name="name"
          className="input"
          placeholder="Your name"
          required
        />
        <input
          type="text"
          name="position"
          className="input"
          placeholder="Your position"
          defaultValue={initUserData.position}
          required
        />

        {!disableFields?.avatar && (
          <div className="box">
            <label>
              Avatar, optional
              <button className="remove-btn" onClick={clearAvatar}>
                X
              </button>
              <input type="file" name="avatar" onChange={fileHandler} />
            </label>
          </div>
        )}
        <input
          type="email"
          defaultValue={initUserData.email}
          name="email"
          className="input"
          placeholder="Your email"
          required
        />
        <input
          type="tel"
          name="phone"
          className="input"
          placeholder="Your mobile number? +49 (0)111 1111 1111"
          defaultValue={initUserData.phone}
        />

        {!disableFields?.localization && (
          <div className="box localization">
            <div>Localization:</div> <br />
            <div className="radio-group">
              <label>
                DE
                <input
                  type="radio"
                  value="de"
                  name="localization"
                  defaultChecked
                />{" "}
              </label>
              <label>
                EN
                <input type="radio" value="en" name="localization" />{" "}
              </label>
              <label>
                UA
                <input type="radio" value="ua" name="localization" />{" "}
              </label>
            </div>
          </div>
        )}
        {!disableFields?.disclaimer && (
          <div className="box disclaimer">
            <div>Disclaimer text(optional):</div> <br />
            <div className="radio-group">
              <label>
                Empty{" "}
                <input
                  type="radio"
                  value="empty"
                  name="disclaimer"
                  defaultChecked
                />{" "}
              </label>{" "}
              <br />
              <label>
                UA
                <input type="radio" value="ua" name="disclaimer" />{" "}
              </label>
            </div>
          </div>
        )}
        <button type="submit" className="submit">
          Get my Signature
        </button>
      </form>
    </div>
  );
};

export default UserData;
